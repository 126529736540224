.smallInput {
    width: 120px;
    float: left;
    text-align: right;
}
img.ui-datepicker-trigger {
    position: absolute;
    right: 20px;
    top: 4px;
    width: 20px;
}